import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Routes from './routes';
import store from './redux/store';
import './assets/styles/app.scss';

axios.defaults.baseURL = 'https://mpower-api.onrender.com/';
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Routes />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
