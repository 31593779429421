import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/mpower-logo.png';

export default function Footer() {
  return (

    <section className="about-section footer">
      <h2 className="about-section__primary">
        <Link className="nav-link white" to="/">
          <img className="logo" src={logo} alt="Marvellous! Power" />
        </Link>
      </h2>
      <p>
        Marvellous! Power Near orthodox church West mangad 680542
        {' '}
        <br />
        +919946904567
      </p>
      <p>
        Marvellous! Power
        { ' ' }
        {new Date().getFullYear()}
        . All rights reserved
      </p>
    </section>

  );
}
