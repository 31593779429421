import React from 'react';
import Navigation from './Navigation';

export default function Header() {
  return (
    <header className="header">
      <Navigation />
      <div className="header-content mt-5">
        <h1 className="header-content__title">Find your power solution</h1>
        <p className="header-content__desc">
          With our years of experience, we are exactly what you are looking for.
        </p>
        <p>
          <a className="btn btn-oval" rel="noreferrer" target="_blank" href={`https://wa.me/+919946904567?text=${encodeURI('I want to book generator, please let me know availability!')}`}>Book Now</a>
        </p>
      </div>
    </header>
  );
}
